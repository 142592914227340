import { actionTypes } from '../actions/cart.action'

const initialState = {
    address: {},
    cart: {
        restaurant_info: {},
        items: [],
        total: {},
        discount: 0,
    },
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.INDEX_ADDRESS:
            state.address =  payload.data;
            return { ...state, ...payload };

        case actionTypes.CHANGE:
            return { 
                ...state,
                cart: {
                    ...state.cart,
                    ...payload
                },
            }

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
