import { actionTypes } from '../actions/search.action'

const initialState = {
    restaurants: [],
    items: [],
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.INDEX:
            state.restaurants =  payload.restaurants
            state.items =  payload.items
            return { ...state, ...payload }

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
