import { HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';
import { findIndex, has, size, sortBy, isNaN, find, isUndefined } from 'lodash';

export const actionTypes = {
    INDEX: 'CART_INDEX',
    CHANGE: 'CART_CHANGE',
    INDEX_ADDRESS: 'INDEX_ADDRESS',
    SUCCESS: 'CART_SUCCESS',
    ERROR: 'CART_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const indexAddressResponse = (payload) => {
    return {
        type: actionTypes.INDEX_ADDRESS,
        payload,
    }
}

export const indexAddress = (userData) => dispatch => {
    return HttpAuth.post('update-user-info', userData)
                .then(res => typeof res !== 'undefined' && dispatch(indexAddressResponse(res.data)))
                .catch(err => {return err;})
}

export const setWithExpiry = (key, value) => {
    const now = new Date()

    //one day expiry
    const item = {
        value: value,
        expiry: now.getTime() + 24 * (60 * 60 * 1000), //24 hours
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current date
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const addRestaurantInfo = (restaurant) => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};
    
    cart.restaurant_info = restaurant;

    setWithExpiry("cart", cart);
}

export const addProduct = (product) => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};
    console.log("restaurant_id", product.restaurant_id);
    
    let items = has( cart, "items" ) ? cart.items : [];
    let itemsCopy = items;
    let productCopy = product;

    console.log("product", product);

    delete productCopy.quantity;

    // se é adicionado um item de outro restaurante, limpa o carrinho antes
    if ( isUndefined( find( items, { restaurant_id: product.restaurant_id } ) ) ) {
        items = [];
        itemsCopy = [];
    }

    let index = findIndex( itemsCopy, productCopy );

    if ( index === -1) {

        let newProduct = product;
        newProduct.quantity = 1;
        newProduct.totaladdonsprice = 0;

        for (let i = 0; i < size(newProduct.selectedaddons); i++) {
            newProduct.totaladdonsprice = newProduct.totaladdonsprice + parseFloat(newProduct.selectedaddons[i].price);
        }

        items.push(newProduct);
    } else {
        let selectedProduct = items[index];
        selectedProduct.quantity = isNaN(selectedProduct.quantity) ? parseInt(1) : parseInt(selectedProduct.quantity) + 1;
        items.splice(index, 1);
        items.push(selectedProduct);
    }
    
    cart.items = items;

    setWithExpiry("cart", cart);
}

export const addTotals = () => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};

    let total = has( cart, "total" ) ? cart.total : {
        totalPrice: 0,
        productQuantity: 0
    };

    let items = has( cart, "items" ) ? cart.items : [];

    if( size(items)>0 ) {
        let newTotalPrice = 0;
        let newTotalProductQuantity = 0;
        let newTotalAddonPrice = 0;

        items.forEach((item)=> {
            let quant = item.quantity;
            
            for (let i = 0; i < quant; i++) {
                let quantAddon = size(item.selectedaddons) > 0 ? size(item.selectedaddons) : 0;
                newTotalPrice = parseFloat(newTotalPrice) + parseFloat(item.price);
                
                if( quantAddon>0 ) {
                    for (let j = 0; j < quantAddon; j++) {
                        newTotalAddonPrice = parseFloat(newTotalAddonPrice) + parseFloat(item.selectedaddons[j].price);
                    }
                } //else {
                    //newTotalPrice = parseFloat(newTotalPrice) + parseFloat(item.price) + ( isNaN( parseFloat(item.addonCategoryPrice) ) ? 0 : parseFloat(item.addonCategoryPrice) );
                    //newTotalAddonPrice = parseFloat(newTotalAddonPrice) + parseFloat(item.addonCategoryPrice);
                //}

            }
            
            newTotalProductQuantity = parseInt(newTotalProductQuantity) + parseInt(item.quantity);
            
        });

        total.totalPrice = parseFloat(newTotalPrice);
        total.productQuantity = parseInt(newTotalProductQuantity);
        total.totalAddonPrice = parseFloat(newTotalAddonPrice);
    }

    cart.total = total;

    setWithExpiry("cart", cart);
}

export const addDiscount = (type=null, value=0, code='') => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};
    delete cart.discount;

    if( type!==null && value!==0 ) {
        cart.discount = {
            type: type,
            value: value,
            code: code,
        };    
    }

    setWithExpiry("cart", cart);
    return cart;
}

export const setCart = (cart) => dispatch => {
    let newCart = cart;

    if(has(newCart, "items")) {
        let newCartItems =  sortBy(newCart.items, ['name']);
        newCart.items = newCartItems;
    
        dispatch( change(newCart) )
    }
}

export const subtractProduct = (product) => {
    let cart = getWithExpiry("cart") !== null ? getWithExpiry("cart") : {};
    let items = has( cart, "items" ) ? cart.items : [];

    let index = findIndex( items, product );

    if ( index !== -1) {
        let selectedProduct = items[index];
        selectedProduct.quantity = parseInt(selectedProduct.quantity) - 1;
        items.splice(index, 1);
        if(selectedProduct.quantity>0) {
            items.push(selectedProduct);
        }
    }

    cart.items = items;

    setWithExpiry("cart", cart);
}