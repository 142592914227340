import { HttpAuth } from '../../config/Http';
import { orderBy, take } from 'lodash';

import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX:   'RESTAURANT_INDEX',
    ITEMS:   'RESTAURANT_ITEMS',
    SUCCESS: 'RESTAURANT_SUCCESS',
    ERROR:   'RESTAURANT_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX

export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

// ITEMS
export const itemsResponse = (payload) => {
    return {
        type: actionTypes.ITEMS,
        payload,
    }
}

export const indexRestaurant = (isDelivery, quant, coords={}) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));

    let rota = isDelivery ? "get-delivery-restaurants" : "get-selfpickup-restaurants";

    return HttpAuth.post(rota, coords)
            .then(res => {
                if(typeof res !== 'undefined') {
                    let lista = take( orderBy(res.data, ['is_ativo'], ['desc']), quant );
                    dispatch(indexResponse(lista));
                    dispatch(changeLoading({open: false}))
                }
            })
            .catch(error => {
                dispatch(changeLoading({open: false}))
    
                if(error.response) {
                    dispatch(errors(error.response.data.errors))
                }
            })
}

// STORE

export const store = () => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('/restaurant')
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(indexResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

// SHOW 

export const show = (slug) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('get-restaurant-info/'+ slug)
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(indexResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

export const items = (slug) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('get-restaurant-items/'+ slug)
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(indexResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

export const indexRestaurantByCategory = (listId) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('get-filtered-restaurants', { category_ids: listId, latitude:'-22.9102584', longitude:'-43.1724358' })
        .then(res => {
            if(typeof res !== 'undefined') {
                let list = orderBy(res.data, ['is_ativo'], ['desc']);
                dispatch(changeLoading({open: false}))
                dispatch(indexResponse(list));
            }
        })
        .catch(error => {
            dispatch(changeLoading({open: false}))

            if(error.response) {
                dispatch(errors(error.response.data.errors))
            }
        })
}
