import { actionTypes } from '../actions/coupon.action'

const initialState = {
    code: "",
    coupon: undefined,
    message: '',
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.CHANGE_COUPON:
            return { 
                ...state, 
                coupon: {
                    ...state.coupon,
                    ...payload
                }
            };

        case actionTypes.CHANGE_CODE:
            return { 
                ...state, 
                code: {
                    ...state.code,
                    ...payload
                }
            };

        case actionTypes.CHANGE_MESSAGE:
            return { 
                ...state, 
                message: {
                    ...state.message,
                    ...payload
                }
            };

        case actionTypes.SUCCESS:
                return { 
                    ...state, 
                    success: payload
                };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
