import { HttpAuth } from '../../config/Http';
import { changeCustom } from './custom.action';
import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX: 'PRODUCT_INDEX',
    CHANGE: 'PRODUCT_CHANGE',
    SUCCESS: 'PRODUCT_SUCCESS',
    ERROR: 'PRODUCT_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX
export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const show = (idProduto) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));

    return HttpAuth.post('get-single-item', {id: idProduto})
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(indexResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

export const images = (idProduto) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));

    return HttpAuth.post('get-single-item-images', {id: idProduto})
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}))
                        return dispatch(indexResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))

                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                });
}

export const productOptions = (name, list) => dispatch => {
    
};
