import { HttpAuth } from '../../config/Http';

import { changeLoading } from './loading.action';
import { changeNotify } from './notify.action';
import { setWithExpiry } from '../../helpers/storage'

export const actionTypes = {
    INDEX_ADDRESSES:   'ADDRESS_INDEX',
    CURRENT_ADDRESS: 'ADDRESS_CURRENT',
    UPDATE_ADDRESS: 'USERINFO_ADDRESS',
    CHANGE:  'ADDRESS_CHANGE',
    SUCCESS: 'ADDRESS_SUCCESS',
    ERROR:   'ADDRESS_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const updateAddressResponse = (payload) => {
    return {
        type: actionTypes.UPDATE_ADDRESS,
        payload,
    }
}

export const indexCurrentAddressResponse = (payload) => {
    return {
        type: actionTypes.CURRENT_ADDRESS,
        payload,
    }
}

export const indexAddressesResponse = (payload) => {
    return {
        type: actionTypes.INDEX_ADDRESSES,
        payload,
    }
}

export const indexAddresses = (userData) => dispatch => {
    return HttpAuth.post('get-addresses', userData)
                .then(res => typeof res !== 'undefined' && dispatch(indexAddressesResponse(res.data)))
                .catch(err => {return err;})
}

export const updateAddress = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));
    return HttpAuth.post('set-default-address', userData)
                .then(res => {
                    if(typeof res !== 'undefined') {
                        dispatch(changeLoading({open: false}));
                        localStorage.removeItem('address');
                        setWithExpiry("address", res.data.default_address);
                        return dispatch(updateAddressResponse(res.data));
                    }
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

export const getCurrentAddress = (userData) => dispatch => {
    return HttpAuth.post('update-user-info', userData)
                .then(res => { 
                    if(typeof res !== 'undefined') {
                        return dispatch(indexCurrentAddressResponse(res.data));  
                    } 
                })
                .catch(err => {return err;})
}

export const newAddress = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando endereço...'
    }));

    return HttpAuth.post('save-address', data)
        .then(res => {
            dispatch(changeLoading({open: false}))

            if(typeof res !== 'undefined') {
                //if(res.data.access_token) {
                    dispatch( changeNotify({
                        open: true,
                        classe: 'success',
                        msg: 'Endereço cadastrado com sucesso.'
                    }) )

                    if(res.data.success) {
                        dispatch( success(true) )
                    }
                //}
            }
        })
        .catch(error => {
            dispatch(changeLoading({open: false}))

            if(error.response) {
                dispatch(errors(error.response.data.errors))
            }
        })
}


