import { actionTypes } from '../actions/custom.action';

const initialState = {
    open: false,
    horizontal: 'center',
    vertical: 'center',
    classe: 'success',
    name: '',
    options: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case actionTypes.CHANGE:
    return { ...state, ...payload }

  default:
    return state
  }
}
