import { actionTypes } from '../actions/restaurant.action'

const initialState = {
    restaurants: [],
    restaurant: {
        products: [],
    },
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.INDEX:
            state.restaurants =  payload;
            return { ...state, ...payload };

        case actionTypes.ITEMS:
            state.restaurant.products = payload;
            return { ...state, ...payload };

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
