import { actionTypes } from '../actions/orderDetails.action'

const initialState = {
    orders: [],
    delivery_details: {},
    delivery_guy_gps_location: {},
    success: false,
    error: {},
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.ORDERS_INDEX:
            state.orders =  payload;
            return { ...state, ...payload };

        case actionTypes.DELIVERY_DETAILS:
            state.delivery_details =  payload;
            return { ...state, ...payload };

        case actionTypes.DELIVERY_GUY_GPS_POSITION:
            state.delivery_guy_gps_location =  payload;
            return { ...state, ...payload };

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
