import { HttpAuth } from '../../config/Http';

import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX: 'CATEGORIA_INDEX',
    SUCCESS: 'CATEGORIA_SUCCESS',
    ERROR: 'CATEGORIA_ERROR'
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX

export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const indexCategoria = () => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));
    return HttpAuth.post('get-restaurant-category-slides')
                .then(res => {
                    typeof res !== 'undefined' && dispatch(indexResponse(res.data));
                    dispatch(changeLoading({open: false}));
                })
                .catch(error => {
                    dispatch(changeLoading({open: false}))
        
                    if(error.response) {
                        dispatch(errors(error.response.data.errors))
                    }
                })
}

