import { HttpAuth } from '../../config/Http';

import { changeLoading } from './loading.action';
import { changeNotify } from './notify.action';
import { setWithExpiry, getWithExpiry } from '../../helpers/storage';

export const actionTypes = {
    CHANGE: 'HOME_CHANGE',
    ERROR: 'HOME_ERROR',
    SUCCESS: 'HOME_SUCCESS',
};

export const change = (payload) => ({
  type: actionTypes.CHANGE,
  payload
})

export const errors = (payload) => ({
  type: actionTypes.ERROR,
  payload
})

export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload
})

export const setIsDelivery = (opcao) => dispatch => {
    dispatch( change({
        isDelivery: opcao,
    }) )
}

export const setUserToken = (token) => dispatch => {
    localStorage.setItem('access_token', token);
    dispatch( change({
        name: '',
        email: '',
        phone: '',
        password: '',
    }) )

    dispatch( success(true) )
}

export const register = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando usuário...'
    }));

    return HttpAuth.post('/register', data)
        .then(res => {
            dispatch(changeLoading({open: false}))

            if(typeof res !== 'undefined') {
                if(res.data.access_token) {
                    dispatch( changeNotify({
                        open: true,
                        classe: 'success',
                        msg: 'Usuário cadastrado com sucesso.'
                    }) )

                    if(res.data.success) {
                        dispatch( success(true) )
                    }
                }
            }
        })
        .catch(error => {
            dispatch(changeLoading({open: false}))

            if(error.response) {
                dispatch(errors(error.response.data.errors))
            }
        })
}

export const setFirstAccessMessage = () => {
    setWithExpiry("first_access", true);
}

export const getFirstAccessMessage = () => {
    const firstAccess = getWithExpiry("first_access");
    return firstAccess;
}