import { actionTypes } from '../actions/delivery.action';

const initialState = {
    credentials: {
        email: '',
        password: '',
    },
    success: false,
    orders: {},
    user_info: {},
    order_info: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.DELIVERY_CHANGE_AUTH:
        return { 
            ...state,
            credentials: {
                ...state.credentials,
                ...payload
            },
        }

    case actionTypes.DELIVERY_SUCCESS_AUTH:
        return { 
            ...state,
            success: payload,
        }

    case actionTypes.DELIVERY_ORDERS:
        return { 
            ...state,
            orders: payload,
        }

    case actionTypes.DELIVERY_USER_INFO:
        return { 
            ...state,
            user_info: payload,
        }

    case actionTypes.DELIVERY_ORDER_INFO:
        return { 
            ...state,
            order_info: payload,
        }

    default:
        return state
    }
}
