import { HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGE: 'CHANGE_AUTH',
    SUCCESS: 'SUCCESS_AUTH'
}

export const changeAuth = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const successAuth = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const setUserToken = (token) => dispatch => {
    localStorage.setItem('access_token', token);
    dispatch( changeAuth({
        email: '',
        password: ''
    }) )

    dispatch( successAuth(true) )
}

export const setUserId = (user_id) => dispatch => {
    localStorage.setItem('user_id', user_id);
    dispatch( changeAuth({
        email: '',
        password: ''
    }) )

    dispatch( successAuth(true) )
}

export const setUserInfo = (data) => dispatch => {
    localStorage.setItem('user_name', data.name);
    localStorage.setItem('user_phone', data.phone);
    localStorage.setItem('user_email', data.email);
}

export const login = credentials => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Autenticando usuário...'
    }))

    return HttpAuth.post('login', {
        email: credentials.email,
        password: credentials.password,
    })
    .then(res => {
        dispatch( changeLoading( { open: false } ) );
        if(typeof res !== 'undefined') {
            if(res.data.data.auth_token) {
                dispatch( setUserToken( res.data.data.auth_token ) );
                dispatch( setUserId( res.data.data.id ) );
                dispatch( setUserInfo( res.data.data ) );
            }
        }
    })
    .catch(error => {
        dispatch( changeLoading( { open: false } ) );
        if(typeof error.response !== 'undefined') {
            if(error.response.state === 401 || error.response.state === 400) {
                dispatch( dispatch( changeNotify( { 
                    open: false,
                    classe: 'error',
                    msg: 'E-mail ou Senha incorretos'
                } ) ) )
            }
        } else {
            dispatch( dispatch( changeNotify( { 
                open: false,
                classe: 'error',
                msg: 'Erro ao se conectar ao servidor'
            } ) ) )
        }
    })
} 

