import { HttpAuth } from '../../config/Http';

export const actionTypes = {
    INDEX: 'NOTIFICATION_INDEX',
    CHANGE: 'NOTIFICATION_CHANGE',
    SUCCESS: 'NOTIFICATION_SUCCESS',
    ERROR: 'NOTIFICATION_ERROR'
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX

export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const indexNotification = (userData) => dispatch => {
    return HttpAuth.post('get-user-notifications', userData)
                .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
                .catch(err => {return err;})
}

export const markViwed = (userData) => dispatch => {
    return HttpAuth.post('mark-one-notification-read', userData)
                .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
                .catch(err => {return err;})
}


