import { actionTypes } from '../actions/checkout.action'

const initialState = {
    troco: 0,
    coupon: [],
    delivery_type: 0,
    location: {
        address: "",
        house: "",
        lat: "",
        lng: "",
        tag: "",
    },
    method: "COD",
    order: [],
    order_comment: "", //pagamento em dinheiro o troco vai aqui como um comentario
    partial_wallet: false,
    payment_token: "",
    token: "",
    total: {
        productQuantity: 0, 
        totalPrice: 0,
    },
    user: {},
    success: false,
    error: {},
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.USERINFO:
            state.user =  payload.data;
            return { ...state, ...payload };

        case actionTypes.CHANGE_TROCO:
            state.troco =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_DELIVERYTYPE:
            state.delivery_type =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_ORDER:
            state.order =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_ORDERCOMMENT:
            state.order_comment =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_PARTIAL_WALLET:
            state.partial_wallet =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_PAYMENT_TOKEN:
            state.payment_token =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE_TOTAL:
            state.total =  payload;
            return { ...state, ...payload };

        case actionTypes.CHANGE:
            return { 
                ...state,
                cart: {
                    ...state.cart,
                    ...payload
                },
            }

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        case actionTypes.ORDER:
            return state;

        default:
            return state;
    }
}

export default data;
