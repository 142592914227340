import { HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX: 'PROMOTION_INDEX',
    SUCCESS: 'PROMOTION_SUCCESS',
    ERROR: 'PROMOTION_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX

export const indexResponse = (payload) => {
    return {
        type: actionTypes.INDEX,
        payload,
    }
}

export const indexPromotion = () => dispatch => {
    let rota = "promo-slider";

    return HttpAuth.post(rota)
            .then(res => {
                typeof res !== 'undefined' && dispatch(indexResponse(res.data.mainSlides));
                dispatch(changeLoading({open: false}));
            })
            .catch(error => {
                dispatch(changeLoading({open: false}))
    
                if(error.response) {
                    dispatch(errors(error.response.data.errors))
                }
            })
}

