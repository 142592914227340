import { actionTypes } from '../actions/home.action'

const initialState = {
    categorias: {
        data: []
    },
    isDelivery: true,
    success: false,
    error: {}
}

export default (state = initialState, { type, payload, isLoadMore }) => {
    switch (type) {

    case actionTypes.INDEX:
        if(isLoadMore) {
            payload.vehicles.data = state.vehicles.data.concat(payload.vehicles.data)
        }

        return { ...state, ...payload }

    case actionTypes.DESTROY:
        return {
            ...state,
            vehicles: {
                ...state.vehicles,
                data: state.vehicles.data.filter(item => item.id !== payload)
            }
        }

    case actionTypes.CHANGE:
        return {
            ...state,
            isDelivery: {
                ...state.isDelivery,
                ...payload,
            }
        }

    case actionTypes.SUCCESS:
        return {
            ...state,
            success: payload
        }

    case actionTypes.ERROR:
        return {
            ...state,
            error: payload
        }

    default:
        return state
    }
}
