import { HttpAuth } from '../../config/Http';

import { changeLoading } from './loading.action';
import { setWithExpiry, getWithExpiry } from '../../helpers/storage'

export const actionTypes = {
    CHANGE_CODE:  'CHANGE_CODE',
    CHANGE_COUPON:  'CHANGE_COUPON',
    CHANGE_MESSAGE: 'CHANGE_MESSAGE',
    SUCCESS: 'COUPON_SUCCESS',
    ERROR:   'COUPON_ERROR',
}

export const changeCode = (payload) => ({
    type: actionTypes.CHANGE_CODE,
    payload
})

export const changeCoupon = (payload) => ({
    type: actionTypes.CHANGE_COUPON,
    payload
})

export const changeMessage = (payload) => ({
    type: actionTypes.CHANGE_MESSAGE,
    payload
})

export const errors = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const applyCoupon = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
    }));

    return HttpAuth.post('apply-coupon', data)
            .then(res => {

                if(typeof res !== 'undefined') {
                    dispatch(changeLoading({open: false}))
                    //setWithExpiry("coupon", res.data);
                    return dispatch(changeCoupon(res.data));
                }

            })
            .catch(error => {
                dispatch(changeLoading({open: false}))
    
                if(error.response) {
                    dispatch(errors(error.response.data.errors))
                }
            })
}
