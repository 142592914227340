import { actionTypes } from '../actions/user.action'

const initialState = {
    pages: [],
    addresses: [],
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.INDEX_PAGES:
            state.pages =  payload
            return { ...state, ...payload }

        case actionTypes.INDEX_ADDRESSES:
            state.addresses =  payload
            return { ...state, ...payload }

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
