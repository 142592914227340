export const setWithExpiry = (key, value) => {
    const now = new Date()

    //one day expiry
    const item = {
        value: value,
        expiry: now.getTime() + 24 * (60 * 60 * 1000), //24 hours
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current date
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}
