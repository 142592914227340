import { combineReducers } from 'redux';
import loadingReducer from './loading.reducer';
import notifyReducer from './notify.reducer';
import alertReducer from './alert.reducer';
import authReducer from './auth.reducer';
import registerReducer from './register.reducer';
import categoriaReducer from './categoria.reducer';
import restaurantReducer from './restaurant.reducer';
import homeReducer from './home.reducer';
import promotionReducer from './promotion.reducer';
import productReducer from './product.reducer';
import customReducer from './custom.reducer';
import notificationReducer from './notification.reducer';
import userReducer from './user.reducer';
import searchReducer from './search.reducer';
import cartReducer from './cart.reducer';
import addressReducer from './address.reducer';
import couponReducer from './coupon.reducer';
import checkoutReducer from './checkout.reducer';
import orderDetailsReducer from './orderDetails.reducer';
import deliveryReducer from './delivery.reducer';

const rootReducer = combineReducers({
    loadingReducer,
    notifyReducer,
    alertReducer,
    authReducer, 
    registerReducer,
    categoriaReducer,
    restaurantReducer,
    homeReducer,
    promotionReducer,
    productReducer,
    customReducer,
    notificationReducer,
    userReducer,
    searchReducer,
    cartReducer,
    addressReducer,
    couponReducer,
    checkoutReducer,
    orderDetailsReducer,
    deliveryReducer,
})

export default rootReducer;