import { actionTypes } from '../actions/product.action'

const initialState = {
    product: {},
    success: false,
    error: {}
}

const data = (state = initialState, { type, payload }) => {
    switch (type) {

        case actionTypes.INDEX:
            state.product =  payload;
            return { ...state, ...payload };

        case actionTypes.SUCCESS:
            return { ...state, success: payload };

        case actionTypes.ERROR:
            return { ...state, error: payload };

        default:
            return state;
    }
}

export default data;
