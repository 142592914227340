import { HttpAuth } from '../../config/Http';

export const actionTypes = {
    INDEX_PAGES: 'USER_PAGES_INDEX',
    INDEX_ADDRESSES: 'USER_ADDRESSES_INDEX',
    CHANGE: 'USER_CHANGE',
    SUCCESS: 'USER_SUCCESS',
    ERROR: 'USER_ERROR',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

// INDEX
export const indexPagesResponse = (payload) => {
    return {
        type: actionTypes.INDEX_PAGES,
        payload,
    }
}

export const indexAddressesResponse = (payload) => {
    return {
        type: actionTypes.INDEX_ADDRESSES,
        payload,
    }
}

export const indexPages = () => dispatch => {
    return HttpAuth.post('get-pages')
                .then(res => typeof res !== 'undefined' && dispatch(indexPagesResponse(res.data)))
                .catch(err => {return err;})
}

export const indexAddresses = (userData) => dispatch => {
    return HttpAuth.post('get-addresses', userData)
                .then(res => typeof res !== 'undefined' && dispatch(indexAddressesResponse(res.data)))
                .catch(err => {return err;})
}
